import React from 'react';
import htmlParser from 'html-react-parser';
import { FaSpotify } from 'react-icons/fa';

import './index.css';

const ContactInfo = (props) => {
    let whatsapp_url = `https://wa.me/${props.company.phone.match( /\d+/g).join('')}`;  
    
    return (
      <>
        <h5>Encontre-nos</h5>
  
        <address>
          <strong>{props.company.name}</strong><br />
          CNPJ: {props.company.cnpj}<br />
          {htmlParser(props.company.address)}<br />
          <abbr title="Telefone">Tel:</abbr> <a href={whatsapp_url} target="_blank" title="WhatsApp" rel="noopener noreferrer">{props.company.phone}</a>
        </address>
  
        <address>
          <strong>E-mail</strong><br />
          <a href="mailto:#">{props.company.email}</a>
        </address>

        <address>
          <a href="https://safetytec.freshdesk.com/support/solutions/articles/44002531639-lgpd" target="_blank">LGPD</a>
        </address>
  
        <address>
          <strong>Redes Sociais:</strong><br />
          <ul className="company-social">
            <li className="social-instagram"><a href="https://www.instagram.com/safetytec.br" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
            <li className="social-facebook"><a href="https://fb.com/SafetyTec" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
            <li className="social-twitter"><a href="https://twitter.com/SafetyTec" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
            <li className="social-youtube"><a href="https://www.youtube.com/c/SafetyTec" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></a></li>
            <li className="social-linkedin"><a href="https://www.linkedin.com/company/safetytec-br" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
            <li className="social-spotify"><a href="https://open.spotify.com/show/51R2DjcWkJ904t4v6NBrEV?si=Cw7lKjPWR6qWjF64qHGKaw" target="_blank" rel="noopener noreferrer"><FaSpotify /></a></li>
          </ul>
        </address>
      </>
    )
  }

export default ContactInfo;