import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import api from '../../services/api';

const subjectsContactForm = [
  'Informações sobre o BuscaEPI.com',
  'Informações sobre ConsultaCA.com',
  'Informações sobre o SafetyEAD',
  'Informações sobre o SafetyCUP',
  'Outros Assuntos'
];

const ContactForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSendind] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const handleChangeName = event => { setName(event.target.value); }
  const handleChangeEmail = event => { setEmail(event.target.value); }
  const handleChangeSubject = event => { setSubject(event.target.value); }
  const handleChangeMessage = event => { setMessage(event.target.value); }

  const reset = () => {
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
    setSendind(false);
  }

  function handleRecaptchaChange(value) {
    setRecaptchaValue(value);
  }

  function handleRecaptchaExpired(value) {
    setRecaptchaValue('');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(recaptchaValue==='') {
      alert('É necessário indicar que NÃO É UM ROBÔ!');
      return;
    }

    setSendind(true);
    const data = {
      name,
      email,
      subject,
      message
    };

    try {
      const response = await api.post('/contact', data)
      alert('Sua mensagem foi enviada com sucesso!');
      console.log(response.data);
      reset();
    } catch (err) {
      alert('Erro ao enviar mensagem, tente novamente.');
    }
    setSendind(false);
  };

  const SubmitButton = () => {
    let labelButton = sending ? "Enviando..." : "Enviar Mensagem";
    return (
      <button type="submit" className="btn btn-skin pull-right" id="btnContactUs" disabled={sending}>
        { labelButton }
      </button>
    )
  }

  const subjectsList = subjectsContactForm.map((item, i) => {
    return (
      <option key={i} value={item}>{item}</option>
    )
  }, this);

  return (
    <form id="contact-form" name="contact-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Nome</label>
            <input type="text" className="form-control" name="name" id="name" placeholder="Informe seu name" required value={name} onChange={handleChangeName} />
          </div>
          <div className="form-group">
            <label>E-mail</label>
            <div className="input-group">
              <span className="input-group-addon">
                <span className="glyphicon glyphicon-envelope"></span>
              </span>
              <input type="email" className="form-control" name="email" id="email" placeholder="Informe seu e-mail" required value={email} onChange={handleChangeEmail} />
            </div>
          </div>
          <div className="form-group">
            <label>Assunto</label>
            <select id="subject" name="subject" className="form-control" required onChange={handleChangeSubject}>
              <option>Selecione:</option>
              {subjectsList}
            </select>
          </div>

          <div className="pull-left">
            <ReCAPTCHA
              sitekey="6LdXvZsUAAAAAJ_8TNFmHrs396GmRoBlx9LKv_Q8"
              onChange={handleRecaptchaChange}
              onExpired={handleRecaptchaExpired}
            />            
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Mensagem</label>
            <textarea name="message" id="message" className="form-control" rows="9" cols="25" required placeholder="Mensagem" value={message} onChange={handleChangeMessage} ></textarea>
          </div>
        </div>
        <div className="col-md-6">
          <SubmitButton />
        </div>
      </div>
    </form>
  )
}

export default ContactForm;