import React from "react";

import imgLogoFiep from "../../assets/img/logo-fiep.jpg";
import imgLogoStartupFarm from "../../assets/img/logo-startup-farm.jpg";
import imgLogoInovativa from "../../assets/img/logo-inovativa.png";
import TeamMember from "./TeamMember";

const About = (props) => {
  const loadTeamMembersList = () => {
    const members = props.team_members.map((item, i) => {
      return (
        <TeamMember
          key={i}
          name={item.name}
          title={item.title}
          avatar={item.avatar}
        />
      );
    }, this);
    return members;
  };

  return (
    <section id="about" className="home-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-lg-offset-2">
            <div className="wow bounceInDown" data-wow-delay="0.4s">
              <div className="section-heading">
                <h2>Quem Somos</h2>
                <i className="fa fa-2x fa-angle-down"></i>
                <p>
                  A SafetyTec foi criada a partir da junção de competências de
                  profissionais das áreas de Segurança do Trabalho e Tecnologia
                  da Informação, com o objetivo em comum de inovar e criar
                  soluções tecnológicas que auxiliem a rotinas relacionadas a
                  Segurança do Trabalho dos milhões de trabalhadores existentes
                  do Brasil.
                </p>
                <p>
                  <strong>Nosso Propósito:</strong>
                  <br />
                  <br />
                  Promover a eficiência operacional das empresas por meio de soluções tecnológicas, 
                  proporcionando informações estratégicas e simplificando a gestão de Saúde e Segurança do Trabalho (SST).
                </p>
                <p>
                  <br />
                  <strong>Nossos Valores:</strong>
                  <br />
                  <br />
                  <strong>Ética:</strong> Seguir os valores que guiam nossas ações, 
                  prezando sempre por respeito, responsabilidade e 
                  transparência entre todos os envolvidos.
                  <br />
                  <br />
                  <strong>Colaboração:</strong> Aprender, dialogar e confiar durante todo o 
                  processo de cooperação entre equipe, parceiros e sociedade.
                  <br />
                  <br />
                  <strong>Fazer a diferença:</strong> Agregar valor social, ambiental e ético alinhado 
                  com objetivos para um mundo melhor através do uso da tecnologia.
                  <br />
                  <br />
                  <strong>Cliente no Coração:</strong> Dedicamo-nos incansavelmente a promover o sucesso e o 
                  bem-estar dos nossos clientes, garantindo atendimentos 
                  excepcionais e tecnologias que atendam às suas necessidades.
                  <br />
                  <br />
                </p>
                <p>
                  <strong>Por onde já passamos:</strong>
                  <br />
                  <br />
                </p>
                <p>
                  <img src={imgLogoFiep} alt="" />
                  <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                    Fomos graduados (Ago/2021) no Programa de Aceleração do
                    Sistema Fiep
                  </span>
                </p>
                <p style={{ marginTop: "60px" }}>
                  <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                    {" "}
                    Fizemos parte do maior Programa de Startups da América
                    Latina (2020)
                  </span>
                  <img
                    src={imgLogoInovativa}
                    style={{
                      width: "200px",
                      height: "79px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </p>
                <p style={{ marginTop: "60px" }}>
                  <img
                    src={imgLogoStartupFarm}
                    style={{ width: "200px", height: "79px" }}
                    alt=""
                  />
                  <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                    Participamos da 15ª Turma do Programa de Aceleração em SP
                    (2016)
                  </span>
                </p>
                <p>
                  <br />
                  <strong>Equipe:</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-lg-offset-5">
            <hr className="marginbot-50" />
          </div>
        </div>

        <div className="row">{loadTeamMembersList()}</div>
      </div>
    </section>
  );
};

export default About;
