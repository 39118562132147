import React from 'react';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

const Contact = (props) => {
  return (
    <section id="contact" className="home-section text-center">
      <div className="heading-contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2">
              <div className="wow bounceInDown" data-wow-delay="0.4s">
                <div className="section-heading">
                  <h2>Entre em Contato</h2>
                  <i className="fa fa-2x fa-angle-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">

        <div className="row">
          <div className="col-lg-2 col-lg-offset-5">
            <hr className="marginbot-50" />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="boxed-grey">
              <ContactForm />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="widget-contact">
              <ContactInfo company={props.company} />
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Contact;