import React, { useEffect, useState } from 'react';

import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Intro from './components/Intro';
import About from './components/About';
import Solutions from './components/Solutions';
import Contact from './components/Contact';
import Footer from './components/Footer';

import siteData from './assets/siteData'

const App = () => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);

  const closeLoading = () => {
    setLoading(false);
    window.closeLoading();
  };

  const initClientJS = () => {
    window.init();
  }

  useEffect(() => {
    setCompany(siteData.contactInfo.company);
    setTeamMembers(siteData.teamMembers);
    closeLoading();
    initClientJS();
  }, []);

  if (loading)
    return <Preloader />;

  return (
    <>
      <Preloader />
      <Navbar />
      <Intro />
      <About team_members={teamMembers} />
      <Solutions />
      <Contact company={company} />
      <Footer />
    </>
  )
}

export default App;