import React from 'react';

const Intro = () => {
  return (
    <section id="intro" className="intro">
      <div className="slogan">
        <h2>TECNOLOGIA E INOVAÇÃO EM <br />SEGURANÇA DO TRABALHO</h2>
      </div>
      <div className="page-scroll">
        <a href="#about" className="btn btn-circle">
          <i className="fa fa-angle-double-down animated"></i>
        </a>
      </div>
    </section>
  )
};

export default Intro;