import React from 'react';

const TeamMember = (props) => {
  let imgAvatar = `img/team/img${props.avatar}.jpg`;
  return (
    <div className="col-xs-12 col-sm-3 col-md-3">
      <div className="wow bounceInUp" data-wow-delay="0.5s">
        <div className="team boxed-grey">
          <div className="inner">
            <h5>{props.name}</h5>
            <p className="subtitle">{props.title}</p>
            <div className="avatar">
              <img src={imgAvatar} alt={props.name} className="img-responsive img-circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMember;