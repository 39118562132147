import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="wow shake" data-wow-delay="0.4s">
              <div className="page-scroll marginbot-30">
                <a href="#intro" id="totop" className="btn btn-circle">
                  <i className="fa fa-angle-double-up animated"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;