import React from 'react';

const Solutions = (props) => {
  
  return (
    <section id="solutions" className="home-section text-center bg-gray">
      <div className="heading-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2">
              <div className="wow bounceInDown" data-wow-delay="0.4s">
                <div className="section-heading">
                  <h2>Soluções</h2>
                  <i className="fa fa-2x fa-angle-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-lg-offset-5">
            <hr className="marginbot-50" />
          </div>
        </div>

        { /* Linha 1 */}
        <div className="row justify-content-center">

          <div className="col-sm-6 col-md-6">
            <div className="wow fadeInLeft" data-wow-delay="0.2s">
              <div className="service-box">
                <div className="service-icon">
                  <a href="https://consultaca.com" target="_blank" rel="noopener noreferrer"><img src="img/solutions/logo-consultaca.png" alt="ConsultaCA" /></a>
                </div>
                <div className="service-desc">
                  <p>A plataforma digital mais completa do país para o controle automatizado de estoque e entrega de Equipamentos de Proteção Individual. Elimine fichas físicas, controle os custos de sua empresa em SST e conecte-se aos maiores fornecedores do país. Tudo isso em em um único lugar!</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6">
            <div className="wow fadeInLeft" data-wow-delay="0.2s">
              <div className="service-box">
                <div className="service-icon">
                  <a href="https://buscaepi.com" target="_blank" rel="noopener noreferrer"><img src="img/solutions/logo-buscaepi.png" alt="BuscaEPI.com" /></a>
                </div>
                <div className="service-desc">
                  <p>A sua fonte confiável e abrangente de informações sobre Equipamentos de Proteção Individual! Verifique a autenticidade, conformidade e certificações dos equipamentos com facilidade. Além disso, tenha acesso direto aos fabricantes para tirar suas dúvidas e obter informações essenciais.</p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}

export default Solutions;