import React from 'react';

const Preloader = () => {  
  return (
    <>
      <div id="preloader">
        <div id="load">
        </div>
      </div>
    </>
  )
}

export default Preloader;