import React from 'react';

import imgLogoSafetyTec from '../../assets/img/logo.png';

const Navbar = () => {
  return (
    <nav className="navbar navbar-custom navbar-fixed-top" role="navigation">
      <div className="container">
        <div className="navbar-header page-scroll">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-main-collapse">
            <i className="fa fa-bars"></i>
          </button>
          <a className="navbar-brand" href="#intro">
            <img src={imgLogoSafetyTec} alt="" className="img-responsive" />
          </a>
        </div>

        <div className="collapse navbar-collapse navbar-right navbar-main-collapse">
          <ul className="nav navbar-nav">
            <li className="active"><a href="#intro">Home</a></li>
            <li><a href="#about">Quem Somos</a></li>
            <li><a href="#solutions">Soluções</a></li>
            <li><a href="#contact">Contato</a></li>
            <li className="dropdown">
              <a href="/" className="dropdown-toggle" data-toggle="dropdown">Acesse <b className="caret"></b></a>
              <ul className="dropdown-menu">
                <li><a href="https://buscaepi.com" target="_blank" rel="noopener noreferrer">BuscaEPI.com</a></li>
                <li><a href="https://consultaca.com" target="_blank" rel="noopener noreferrer">ConsultaCA.com</a></li>
                <li><a href="https://consultaca.com/safetycup" target="_blank" rel="noopener noreferrer">SafetyCUP</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;